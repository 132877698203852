import * as types from "./types";

// fetch data
const fetchRequest = () => ({
   type: types.FETCH_REQUEST,
});

const fetchRequestCompleted = (data, emptyFilter) => ({
   type: types.FETCH_DETAILS_COMPLETED,
   payload: {
      photosetsData: data,
      emptyFilter,
   },
});

const fetchRequestFailed = error => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: {
      error,
   },
});
const resetActivePhotoset = () => ({
   type: types.RESET_ACTIVE_PHOTOSET,
});

// fetch data by filter
const fetchRequestByFilter = () => ({
   type: types.FETCH_BY_FILTER_REQUEST,
});

const fetchRequestByFilterCompleted = data => ({
   type: types.FETCH_BY_FILTER_DETAILS_COMPLETED,
   payload: data,
});

const fetchRequestByFilterFailed = error => ({
   type: types.FETCH_BY_FILTER_DETAILS_FAILED,
   payload: {
      error,
   },
});

// get Photoset by id
const fetchPhotosetByIdRequest = () => ({
   type: types.FETCH_PHOTOSET_BY_ID_REQUEST,
});

const fetchPhotosetByIdCompleted = id => ({
   type: types.FETCH_PHOTOSET_BY_ID_COMPLETED,
   payload: id,
});
const fetchPhotosetByIdFailed = error => ({
   type: types.FETCH_PHOTOSET_BY_ID_FAILED,
   payload: {
      error,
   },
});

// fetch next data
const fetchNextPhotosetRequest = () => ({
   type: types.FETCH_NEXT_PHOTOSETS_REQUEST,
});

const fetchNextPhotosetCompleted = data => ({
   type: types.FETCH_NEXT_PHOTOSETS_COMPLETED,
   payload: data,
});

const fetchNextPhotosetFailed = error => ({
   type: types.FETCH_NEXT_PHOTOSETS_FAILED,
   payload: {
      error,
   },
});

// Like this photoset

const toggleLikeCompleted = (id, isLike) => ({
   type: types.TOGGLE_LIKE_COMPLETED,
   payload: { id, isLike },
});

const setFilters = data => ({
   type: types.SET_FILTERS,
   payload: data,
});

const clearState = payload => ({
   type: types.CLEAR_STATE,
   payload,
});
const updatePhotosetById = (data, id) => ({
   type: types.UPDATE_PHOTOSET_BY_ID,
   payload: { data, id },
});

const toggleWatchedCompleted = (id) => ({
   type: types.TOGGLE_WATCHED_COMPLETED,
   payload: id,
})

const updateDataAfterPurchaseCompleted = (data) => ({
   type: types.UPDATE_DATA_AFTER_PURCHASE_COMPLETED,
   payload: data,
})

const setIsInitedState = (bool) => ({
   type: types.SET_IS_INITED_STATE,
   payload: bool,
});

const onChangeFavoriteItemCompleted = (id, isDetailsPage) => ({
   type: types.ON_CHANGE_FAVORITE_ITEM_COMPLETED,
   payload: { id, isDetailsPage },
})

const onChangeLoadingState = (payload) => ({
   type: types.CHANGE_LOADING_STATE,
   payload,
})


export {
   fetchRequest,
   fetchRequestCompleted,
   fetchRequestFailed,

   fetchRequestByFilter,
   fetchRequestByFilterCompleted,
   fetchRequestByFilterFailed,

   fetchNextPhotosetRequest,
   fetchNextPhotosetCompleted,
   fetchNextPhotosetFailed,

   fetchPhotosetByIdRequest,
   fetchPhotosetByIdCompleted,
   fetchPhotosetByIdFailed,

   toggleLikeCompleted,
   setFilters,
   clearState,
   updatePhotosetById,

   toggleWatchedCompleted,
   resetActivePhotoset,
   updateDataAfterPurchaseCompleted,
   setIsInitedState,
   onChangeFavoriteItemCompleted,
   onChangeLoadingState,
};
