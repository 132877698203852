export const FETCH_REQUEST = "videos/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "videos/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "videos/FETCH_DETAILS_FAILED";

export const FETCH_BY_FILTER_REQUEST = "videos/FETCH_BY_FILTER_REQUEST";
export const FETCH_BY_FILTER_DETAILS_COMPLETED = "videos/FETCH_BY_FILTER_DETAILS_COMPLETED";
export const FETCH_BY_FILTER_DETAILS_FAILED = "videos/FETCH_BY_FILTER_DETAILS_FAILED";

export const FETCH_NEXT_DATA_REQUEST = "videos/FETCH_NEXT_DATA_REQUEST";
export const FETCH_NEXT_DATA_COMPLETED = "videos/FETCH_NEXT_DATA_COMPLETED";
export const FETCH_NEXT_DATA_FAILED = "videos/FETCH_NEXT_DATA_FAILED";

export const TOGGLE_LIKE_REQUEST = "videos/TOGGLE_LIKE_REQUEST";
export const TOGGLE_LIKE_COMPLETED = "videos/TOGGLE_LIKE_COMPLETED";
export const TOGGLE_LIKE_FAILED = "videos/TOGGLE_LIKE_FAILED";

export const TOGGLE_WATCHED_COMPLETED = "videos/TOGGLE_WATCHED_COMPLETED";

export const FETCH_VIDEO_BY_ID_REQUEST = 'videos/FETCH_VIDEO_BY_ID_REQUEST';
export const FETCH_VIDEO_BY_ID_COMPLETED = 'videos/FETCH_VIDEO_BY_ID_COMPLETED';
export const FETCH_VIDEO_BY_ID_FAILED = 'videos/FETCH_VIDEO_BY_ID_FAILED';
export const SET_FILTERS = 'videos/SET_FILTERS';
export const CLEAR_STATE = 'videos/CLEAR_STATE';
export const UPDATE_VIDEO_BY_ID = 'videos/UPDATE_VIDEO_BY_ID';
export const CLEAR_VIDEO_BY_ID = 'videos/CLEAR_VIDEO_BY_ID';
export const UPDATE_DATA_AFTER_PURCHASE = 'videos/UPDATE_DATA_AFTER_PURCHASE';

export const GET_UPGRADE_PRICING_LIST = 'videos/GET_UPGRADE_PRICING_LIST';
export const DELETE_UPGRADE_PRICING_LIST = 'videos/DELETE_UPGRADE_PRICING_LIST';
export const SET_IS_INITED_STATE = "videos/SET_IS_INITED_STATE";
export const ON_CHANGE_FAVORITE_ITEM_COMPLETED = "videos/ON_CHANGE_FAVORITE_ITEM_COMPLETED";
export const CHANGE_LOADING_STATE = "videos/CHANGE_LOADING_STATE";
