import * as types from "./types";

// fetch data
const fetchRequest = () => ({
   type: types.FETCH_REQUEST,
});

const fetchRequestCompleted = (data, emptyFilters) => ({
   type: types.FETCH_DETAILS_COMPLETED,
   payload: {
      videosData: data,
      emptyFilters,
   },
});

const fetchRequestFailed = error => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: {
      error,
   },
});


// filter videos data
const fetchRequestByFilter = () => ({
   type: types.FETCH_BY_FILTER_REQUEST,
});

const fetchRequestByFilterCompleted = data => ({
   type: types.FETCH_BY_FILTER_DETAILS_COMPLETED,
   payload: {
      videosData: data,
   },
});

const fetchRequestByFilterFailed = error => ({
   type: types.FETCH_BY_FILTER_DETAILS_FAILED,
   payload: {
      error,
   },
});

// go to next page
const fetchNextVideoRequest = () => ({
   type: types.FETCH_NEXT_DATA_REQUEST,
});

const fetchNextVideoCompleted = data => ({
   type: types.FETCH_NEXT_DATA_COMPLETED,
   payload: {
      videosData: data,
   },
});

const fetchNextVideoFailed = error => ({
   type: types.FETCH_NEXT_DATA_FAILED,
   payload: {
      error,
   },
});

// Like this video
const toggleLikeRequest = () => ({
   type: types.TOGGLE_LIKE_REQUEST,
});

const toggleLikeCompleted = (id, isLike) => ({
   type: types.TOGGLE_LIKE_COMPLETED,
   payload: { id, isLike },
});

const toggleLikeFailed = error => ({
   type: types.TOGGLE_LIKE_FAILED,
   payload: {
      error,
   },
});

const toggleWatchedCompleted = id => ({
   type: types.TOGGLE_WATCHED_COMPLETED,
   payload: id,
})
// get Video by id
const fetchVideoByIdRequest = () => ({
   type: types.FETCH_VIDEO_BY_ID_REQUEST,
});

const fetchVideoByIdCompleted = data => ({
   type: types.FETCH_VIDEO_BY_ID_COMPLETED,
   payload: data,
});
const fetchVideoByIdFailed = error => ({
   type: types.FETCH_VIDEO_BY_ID_FAILED,
   payload: {
      error,
   },
});
const clearVideoDataById = () => ({
   type: types.CLEAR_VIDEO_BY_ID,
});

const setFilters = data => ({
   type: types.SET_FILTERS,
   payload: data,
});
const clearState = payload => ({
   type: types.CLEAR_STATE,
   payload,
});
const updateVideoById = (data, id) => ({
   type: types.UPDATE_VIDEO_BY_ID,
   payload: { data, id },
});

const updateDataAfterPurchaseCompleted = (data) => ({
   type: types.UPDATE_DATA_AFTER_PURCHASE,
   payload: data,
})

const getPricingUpgradeList = (data) => ({
   type: types.GET_UPGRADE_PRICING_LIST,
   payload: data,
})

const deletePricingUpgradedList = () => ({
   type: types.DELETE_UPGRADE_PRICING_LIST,
})
const setIsInitedState = (bool) => ({
   type: types.SET_IS_INITED_STATE,
   payload: bool,
});

const onChangeFavoriteItemCompleted = (id, isDetailsPage) => ({
   type: types.ON_CHANGE_FAVORITE_ITEM_COMPLETED,
   payload: { id, isDetailsPage },
})

const onChangeLoadingState = (payload) => ({
   type: types.CHANGE_LOADING_STATE,
   payload,
})


export {
   fetchRequest,
   fetchRequestCompleted,
   fetchRequestFailed,

   fetchRequestByFilter,
   fetchRequestByFilterCompleted,
   fetchRequestByFilterFailed,
   fetchNextVideoRequest,
   fetchNextVideoCompleted,
   fetchNextVideoFailed,
   toggleLikeRequest,
   toggleLikeCompleted,
   toggleLikeFailed,
   toggleWatchedCompleted,

   fetchVideoByIdRequest,
   fetchVideoByIdCompleted,
   fetchVideoByIdFailed,
   setFilters,
   clearState,
   updateVideoById,
   clearVideoDataById,
   updateDataAfterPurchaseCompleted,
   getPricingUpgradeList,
   deletePricingUpgradedList,
   setIsInitedState,
   onChangeFavoriteItemCompleted,
   onChangeLoadingState,
};
