import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'common/components/elements/icons';
import FilterContent from './content'

const Filter = ({
   name,
   type,
   className,
   desktopType,
   data,
   isMultiplSelect,
   activeSelectedValue,
   isSearchable,
   onScrollContent,
   filterNameKey,
   isTopSeparate,
   onFilterChange,
   onClickShowResult,
   searchPlaceholder,
   isLoadinngData,
   primaryIcon,
   filterValueKey,
   onClickClear,
   onCloseContent,
   children,
   onOpenFilter,
   isChangedInChildren,
   isClosedOutside,
   height,
   isAutoSave,
   onClickSelect,
   iconName,
   iconSize,
   insideChat,
   contentStyles,
   showNewStyles,
   iconColor,
   bgColor,
   textColor,
   version,
   padding,
   leftIconName,
   isEmpty,
   labelIcon,
   labelIconColor,
   activValueClassName,
   showNewRadioStyles,
   viewClassName,
   viewWidth,
   hasClearButton,
   textWrapperClassName,
   contentMarginToLeftIcon,
   activeValueColor,
   contentCardsStyle,
   hasCloseButton,
   hasFilterButtonContent,
   checkboxLabelClassname,
   rotateIconOnOpenContent,
   showResultOnFilterChange,
   notDisableShowResultsButton,
   disabled,
   filterDescription,
   radioClassName,
   contentClassName,
   filterClassName,
   checkboxSize,
   showCloseButton,
   updateDataOnChangeLoading, // when loading gets false, filter data is not updated
   showClearButton,
   activeSelectedValueClassName,
   disabledState,
   useIconLabel,
   showSelectAll,
   isSelectedAll,
   labelTooltipText,
   classNamesToRemove,
   isLoadingNextPageData,
   contentId,
   useMemberAvatar,
   onSearch,
   notShowTooltip,
   notTruncate,
   isNewLabel,
   wrapActiveValue,
   activeValueWrapperClassName,
   searchValue,
   checkboxClassName,
   truncateTextSize,
   nameClassName,
   checkboxMargin,
   useCountryFlag,
   showCloseButtonInsteadOfClear,
   scrollToCheckedElement,
   tooltipText,
}) => {
   let responsiveFilterRef = React.createRef();
   const [openContent, setOpenContent] = useState(false);
   const [openContentType, setOpenContentType] = useState('');
   const [filters, setFilters] = useState(data)
   const [style, setStyle] = useState({ })
   const [isChange, setIsChange] = useState(false)
   const [animate, setAnimate] = useState('up');
   let localSearchValue = ''
   let timerFetching = null;

   // eslint-disable-next-line react-hooks/exhaustive-deps
   function handleClickOutside(event) {
      if(responsiveFilterRef.current && !responsiveFilterRef.current.contains(event.target) && isClosedOutside) {
         onCloseDkContent()
      }
   }

   useEffect(() => {
      if(!useCountryFlag) {
         setFilters(data)
      } else {
         changeData(0, 125);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data])

   const onCloseDkContent = () => {
      if(onCloseContent && openContent){
         onCloseContent(openContentType);
      }
      setOpenContent(false)

      if(!useCountryFlag) {
         setFilters(data)
      } else {
         changeData(0, 125);
      }
   }

   useEffect(() => {
      if(desktopType === 'mobile'){
         return
      }
      document.addEventListener('mousedown', (e) => handleClickOutside(e));
      return () => {
         clearTimeout(timerFetching);
         document.removeEventListener('mousedown', (e) => handleClickOutside(e));
      };
   }, [data, desktopType, timerFetching, handleClickOutside]);

   function onSearchChange(value) {

      !!onSearch && onSearch(value)

      let val = value ? value.replaceAll('\\', '\\\\') : ''
      localSearchValue = value

      // eslint-disable-next-line array-callback-return
      let newList = [...data].filter(cast => {
         if(cast[filterNameKey] && cast[filterNameKey].toLowerCase().search(val.toLowerCase()) !== -1) {
            return cast
         }
      })
      setFilters(newList)
   }

   function onSetOpenContent(bool) {
      const close = () => {
         setOpenContent(bool)
         setAnimate('up')
         if(onOpenFilter) {
            onOpenFilter(bool)
         }
      }
      if('descktop' === desktopType || desktopType === 'desktop') {
         close()
      } else {
         if(!bool) {
            setAnimate('down');
            timerFetching = setTimeout(() => {
               close()
            }, 800);
         } else {
            close()
         }
      }
   }

   useEffect(() => {
      const elm = responsiveFilterRef.current
      if(elm && (desktopType === 'descktop' || desktopType === 'desktop')) {
         const xPosition =  elm.getBoundingClientRect().x
         xPosition >= 301 ? setStyle({ right: '4px' }) :  setStyle({ left: '4px' })
      }
      // eslint-disable-next-line
   }, [activeSelectedValue?.name, desktopType, window?.innerWidth, openContent])

   useEffect(() => {
      if(updateDataOnChangeLoading) {
         setFilters(data)
      }

      // eslint-disable-next-line
   }, [isLoadinngData, updateDataOnChangeLoading])

   const contentRef = useRef(null);

   useEffect(() => {
      const container = contentRef.current;

      if(!container || !useCountryFlag) {
         return
      }

      container.addEventListener('scroll', handleScroll);
      return () => {
         container.removeEventListener('scroll', handleScroll);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [openContent]);

   const changeData = (start, end) => {
      if(start === 0) {
         setFilters([
            ...data.slice(start, end),
         ]);
      } else {
         setFilters([
            ...filters,
            ...data.slice(start, end),
         ]);
      }
   }

   // eslint-disable-next-line no-undef
   // const debouncedXxx = _.debounce((start, end) => xxx(start, end), 200)

   const handleScroll = () => {
      if(!!localSearchValue) return

      const container = contentRef.current;
      const containerClientHeight = container.clientHeight;
      const scrollTop = container.scrollTop;

      if(scrollTop > containerClientHeight - 200 && filters?.length < data?.length) {
         const nextPageStart = filters.length;
         const nextPageEnd = data.length;
         changeData(nextPageStart, nextPageEnd);
      }
   };

   return (
      <div
         ref={ responsiveFilterRef }
         className={ `flex flex-1 relative responsive-filter-content ${ filterClassName }` }
      >
         <div
            className={ cx({
               [`flex h-${ height } items-center ${ padding } cursor-pointer select-none ${ className } dark:border-divider-dark border-divider whitespace-normal`]: true,
               'hover:bg-hover dark:hover:bg-hover-dark': !disabled,
               'rounded-lg bg-major-dark dark:bg-major': !bgColor,
               'bg-panel dark:bg-panel-dark border border-solid rounded-lg dark:border-divider-dark border-divider text-major dark:text-major-dark leading-5 font-normal !px-[16px] py-[10px]': showNewStyles,
               [`${ bgColor }`]: bgColor,
               // '!px-4 !py-[10px]': !!contentCardsStyle,
               '!cursor-default': disabled,
            }) }
            role='presentation'
            onClick={ () => {
               if(disabled) {
                  return;
               }
               if(!!onClickSelect) {
                  onClickSelect();
               } else {
                  const elm = responsiveFilterRef.current
                  if(elm && desktopType === 'descktop') {
                     const xPosition =  elm.getBoundingClientRect().x
                     xPosition >= 300 ? setStyle({ right: '4px' }) :  setStyle({ left: '4px' })
                  }
                  clearTimeout(timerFetching);
                  onCloseContent(openContentType)
                  setOpenContent(!openContent)
                  onSetOpenContent(!openContent);
                  setOpenContentType(name);
               }
            } }
            data-tooltip-id={ tooltipText ? 'ams-top-tooltip' : undefined }
            data-tooltip-content={ tooltipText ? tooltipText : undefined }
         >
            <div className={ `flex flex-1 items-center ${ !!leftIconName ? 'gap-2' : '' }  ${ showNewStyles ? '' : '' } ${ textWrapperClassName }`  } style={ { width: 'max-content' } }>
               {
                  !!Object.keys(activeSelectedValue).length && activeSelectedValue.status && !!Object.keys(activeSelectedValue.status).length ? (
                     <div className={ !!labelIcon ? ` flex items-center` : 'mr-5' }>
                        <p className={ cx('flex items-center justify-center w-18 h-6 text-major-dark rounded-full text-xs', activeSelectedValue.status.color, ` ${ !!labelIcon && `!w-[88px] mr-2` }`, `${ isNewLabel && `w-[104px]` }`) }>
                           {
                              !!labelIcon && (
                                 <Icon name={ labelIcon } size={ 'xs' } color={ iconColor } className={ `${ !!labelIconColor && labelIconColor } mr-2`  } />
                              )
                           }
                           {activeSelectedValue.status.name}
                        </p>
                     </div>
                  ) : ('')
               }
               {
                  !!leftIconName && (
                     <Icon name={ leftIconName } size={ iconSize } color={ iconColor } />
                  )
               }
               {
                  wrapActiveValue ?
                     <div className={ `${ activeValueWrapperClassName }` }>
                        {
                           Boolean(name) && (
                              <span
                                 className={ `text-major leading-tight dark:text-major-dark filter-selected-name ${ insideChat && desktopType !== 'mobile' ? 'truncate' : '' } ${ showNewStyles ? `text-major dark:text-major-dark text-base leading-5 font-${ (activeSelectedValue && !!activeSelectedValue.name) ? 'semibold' : 'normal' }` : 'font-medium' } ${ disabled && '!text-disabled' }` }
                              >
                                 {name}{activeSelectedValue && !!activeSelectedValue.name && name ? ':' : ''}
                              </span>
                           )
                        }
                        {
                           !!activeSelectedValue.name && (
                              <span
                                 style={ {
                                    fontSize: contentCardsStyle?.fontSize,
                                 } }
                                 className={ ` ${
                                    showNewStyles ?
                                       `font-normal ${ activeValueColor } dark:text-major-dark  text-base leading-5` : 'font-medium skin-primary-text leading-tight' }
                                 ${ activeSelectedValue.name.length > 18 ? 'w-[156px] truncate' : '' }
                                 ${ activeSelectedValue.value === 'placeholder' ? `!text-l-placeholder` : `` }
                                 ${ activeSelectedValueClassName }` }>&nbsp;{activeSelectedValue.name}
                              </span>
                           )
                        }

                     </div>
                     :
                     <>
                        {
                           Boolean(name) && (
                              <span
                                 className={ `text-major leading-tight dark:text-major-dark filter-selected-name ${ insideChat && desktopType !== 'mobile' ? 'truncate' : '' } ${ showNewStyles ? `text-major dark:text-major-dark text-base leading-5 font-${ (activeSelectedValue && !!activeSelectedValue.name) ? 'semibold' : 'normal' }` : 'font-medium' } ${ disabled && '!text-disabled' } ${ nameClassName }` }
                              >
                                 {name}{activeSelectedValue && !!activeSelectedValue.name && name ? ':' : ''}
                              </span>
                           )
                        }
                        {
                           !!activeSelectedValue.name && (
                              <span
                                 style={ {
                                    fontSize: contentCardsStyle?.fontSize,
                                 } }
                                 className={ ` ${
                                    showNewStyles ?
                                       `font-normal ${ activeValueColor } dark:text-major-dark  text-base leading-5` : 'font-medium skin-primary-text leading-tight' }
                              ${ activeSelectedValue.name.length > 18 ? 'w-[156px] truncate' : '' }
                              ${ activeSelectedValue.value === 'placeholder' ? `!text-l-placeholder` : `` }
                              ${ activeSelectedValueClassName }` }>&nbsp;{activeSelectedValue.name}
                              </span>
                           )
                        }
                     </>
               }
               <div className='h-5 w-5 flex items-center justify-center ml-2'>
                  <Icon
                     name={ showNewStyles ? 'arrow-down' : `${ iconName || 'dropdown' }` }
                     size={ iconSize || 'xl' }
                     color={ showNewStyles ? 'secondary' : iconColor }
                     darkColor={ showNewStyles ? 'secondary-dark' : iconColor }
                     className={ `${ textColor } ${ disabled && '!text-disabled' }` }
                     style={ {
                        transform: rotateIconOnOpenContent ? (openContent ? 'rotate(-180deg)' : 'rotate(0deg)') : '',
                     } }
                  />
               </div>
            </div>

         </div>
         {
            openContent && (
               <FilterContent
                  onSearchChange={ onSearchChange }
                  data={ filters }
                  desktopType={ desktopType }
                  isMultiplSelect={ isMultiplSelect }
                  isSearchable={ isSearchable }
                  activeSelectedValue={ activeSelectedValue }
                  onScrollContent={ onScrollContent }
                  filterNameKey={ filterNameKey }
                  onCloseContent={ () => {
                     clearTimeout(timerFetching);
                     onCloseContent(name);
                     onSetOpenContent(false);
                     setFilters(data)
                  } }
                  isTopSeparate={ isTopSeparate }
                  onFilterChange={ (value, name) => {
                     onFilterChange(value, name);
                     setIsChange(true)
                     if(!isMultiplSelect) {
                        clearTimeout(timerFetching);
                        onSetOpenContent(false)
                        setFilters(data);
                     } else {
                        if(showResultOnFilterChange) {
                           onClickShowResult();
                        }
                     }
                  } }
                  onClickShowResult={ () => {
                     setOpenContent(false);
                     if(isChange || isChangedInChildren) {
                        onClickShowResult();
                     }
                     clearTimeout(timerFetching);
                     onSetOpenContent(false);
                     setFilters(data);
                  } }
                  onAutoFilter={ (value, name) => {
                     onFilterChange(value, name, true);
                  } }
                  searchPlaceholder={ searchPlaceholder }
                  isLoadinngData={ isLoadinngData }
                  primaryIcon={ primaryIcon }
                  style={ name === 'Access' ? { right: '15px' } : style }
                  filterValueKey={ filterValueKey }
                  onClickClear={ () => {
                     clearTimeout(timerFetching);
                     onClickClear()
                     setOpenContent(false)
                     onSetOpenContent(false);
                     setFilters(data);
                  } }
                  isSort={ type === 'sort' }
                  isDisabled={ ((!!activeSelectedValue && !!activeSelectedValue.value && !!activeSelectedValue.value.length) || notDisableShowResultsButton) ? false : true   }
                  animate={ animate }
                  isAutoSave={ isAutoSave }
                  onCloseDkContent={ _ => onCloseDkContent() }
                  contentStyles={ contentStyles }
                  version={ version }
                  isEmpty={ isEmpty }
                  showNewRadioStyles={ showNewRadioStyles }
                  viewClassName={ viewClassName }
                  viewWidth={ viewWidth }
                  hasClearButton={ hasClearButton }
                  contentMarginToLeftIcon={ contentMarginToLeftIcon }
                  valueColor={ activeValueColor }
                  contentCardsStyle={ contentCardsStyle }
                  hasCloseButton={ hasCloseButton }
                  hasFilterButtonContent={ hasFilterButtonContent }
                  checkboxLabelClassname={ checkboxLabelClassname }
                  filterDescription={ filterDescription }
                  radioClassName={ radioClassName }
                  contentClassName={ contentClassName }
                  checkboxSize={ checkboxSize }
                  showCloseButton={ showCloseButton }
                  showClearButton={ showClearButton }
                  disabledState={ disabledState }
                  useIconLabel={ useIconLabel }
                  showSelectAll={ showSelectAll }
                  isSelectedAll={ isSelectedAll }
                  labelTooltipText={ labelTooltipText }
                  classNamesToRemove={ classNamesToRemove }
                  isLoadingNextPageData={ isLoadingNextPageData }
                  contentId={ contentId }
                  useMemberAvatar={ useMemberAvatar }
                  notShowTooltip={ notShowTooltip }
                  notTruncate={ notTruncate }
                  searchValue={ searchValue }
                  checkboxClassName={ checkboxClassName }
                  truncateTextSize={ truncateTextSize }
                  checkboxMargin={ checkboxMargin }
                  useCountryFlag={ useCountryFlag }
                  contentRef={ contentRef }
                  showCloseButtonInsteadOfClear={ showCloseButtonInsteadOfClear }
                  scrollToCheckedElement={ scrollToCheckedElement }
               >
                  { children }
               </FilterContent>
            )
         }
      </div>
   );
};

Filter.defaultProps = {
   type: 'filter',
   className: '',
   desktopType: 'mobile',
   isMultiplSelect: false,
   isSearchable: false,
   isTopSeparate: false,
   filterNameKey: 'name',
   filterValueKey: 'value',
   activeSelectedValue: {},
   onFilterChange: () => {},
   onClickShowResult: () => {},
   onClickClear: () => {},
   onCloseContent: () => {},
   isChangedInChildren: false,
   isClosedOutside: true,
   height: '12',
   isAutoSave: false,
   insideChat: false,
   showNewStyles: true,
   iconColor: 'secondary',
   textColor: 'text-major leading-tight dark:text-major-dark',
   iconName: 'dropdown',
   version: 'v2',
   padding: 'pl-3 pr-2',
   showNewRadioStyles: false,
   viewClassName: ``,
   viewWidth: ``,
   hasClearButton: true,
   textWrapperClassName: '',
   contentMarginToLeftIcon: 8,
   activeValueColor: 'text-major',
   contentCardsStyle: {},
   hasCloseButton: true,
   hasFilterButtonContent: true,
   checkboxLabelClassname: '',
   rotateIconOnOpenContent: false,
   closeOnSelectFilter: false,
   showResultOnFilterChange: true,
   notDisableShowResultsButton: false,
   disabled: false,
   filterDescription: '',
   contentClassName: '',
   filterClassName: '',
   checkboxSize: '6',
   showCloseButton: false,
   updateDataOnChangeLoading: false,
   showClearButton: true,
   activeSelectedValueClassName: '',
   disabledState: [],
   labelTooltipText: '',
   classNamesToRemove: [],
   notTruncate: false,
   truncateTextSize: '16px',
   nameClassName: '',
   useCountryFlag: false,
   showCloseButtonInsteadOfClear: false,
   scrollToCheckedElement: false,
};

Filter.propTypes = {
   type: PropTypes.string,
   className: PropTypes.string,
   name: PropTypes.string,
   desktopType: PropTypes.string,
   data: PropTypes.array.isRequired,
   isMultiplSelect: PropTypes.bool,
   isSearchable: PropTypes.bool,
   activeSelectedValue: PropTypes.any,
   onScrollContent: PropTypes.func,
   filterNameKey: PropTypes.string,
   isTopSeparate: PropTypes.bool,
   onFilterChange: PropTypes.func,
   onClickShowResult: PropTypes.func,
   searchPlaceholder: PropTypes.string,
   primaryIcon: PropTypes.string,
   filterValueKey: PropTypes.string,
   isLoadinngData: PropTypes.bool,
   onClickClear: PropTypes.func,
   onCloseContent: PropTypes.func,
   children: PropTypes.any,
   onOpenFilter: PropTypes.func,
   isChangedInChildren: PropTypes.bool,
   isClosedOutside: PropTypes.bool,
   height: PropTypes.string,
   isAutoSave: PropTypes.bool,
   onClickSelect: PropTypes.func,
   iconName: PropTypes.string,
   iconSize: PropTypes.string,
   insideChat: PropTypes.bool,
   contentStyles: PropTypes.object,
   showNewStyles: PropTypes.bool,
   iconColor: PropTypes.string,
   bgColor: PropTypes.string,
   textColor: PropTypes.string,
   padding: PropTypes.string,
   version: PropTypes.string,
   leftIconName: PropTypes.string,
   isEmpty: PropTypes.bool,
   labelIcon: PropTypes.string,
   labelIconColor: PropTypes.string,
   activValueClassName: PropTypes.string,
   showNewRadioStyles: PropTypes.bool,
   viewClassName: PropTypes.string,
   viewWidth: PropTypes.string,
   hasClearButton: PropTypes.bool,
   textWrapperClassName: PropTypes.string,
   contentMarginToLeftIcon: PropTypes.number,
   activeValueColor: PropTypes.string,
   contentCardsStyle: PropTypes.object,
   hasCloseButton: PropTypes.bool,
   hasFilterButtonContent: PropTypes.bool,
   checkboxLabelClassname: PropTypes.string,
   rotateIconOnOpenContent: PropTypes.bool,
   showResultOnFilterChange: PropTypes.bool,
   notDisableShowResultsButton: PropTypes.bool,
   disabled: PropTypes.bool,
   filterDescription: PropTypes.string,
   radioClassName: PropTypes.string,
   contentClassName: PropTypes.string,
   filterClassName: PropTypes.string,
   checkboxSize: PropTypes.string,
   showCloseButton: PropTypes.bool,
   updateDataOnChangeLoading: PropTypes.bool,
   showClearButton: PropTypes.bool,
   activeSelectedValueClassName: PropTypes.string,
   disabledState: PropTypes.array,
   useIconLabel: PropTypes.bool,
   showSelectAll: PropTypes.bool,
   isSelectedAll: PropTypes.bool,
   labelTooltipText: PropTypes.string,
   classNamesToRemove: PropTypes.array,
   isLoadingNextPageData: PropTypes.bool,
   contentId: PropTypes.string,
   useMemberAvatar: PropTypes.bool,
   onSearch: PropTypes.func,
   notShowTooltip: PropTypes.bool,
   notTruncate: PropTypes.bool,
   isNewLabel: PropTypes.bool,
   wrapActiveValue: PropTypes.bool,
   activeValueWrapperClassName: PropTypes.string,
   searchValue: PropTypes.string,
   checkboxClassName: PropTypes.string,
   truncateTextSize: PropTypes.string,
   nameClassName: PropTypes.string,
   checkboxMargin: PropTypes.string,
   useCountryFlag: PropTypes.bool,
   showCloseButtonInsteadOfClear: PropTypes.bool,
   scrollToCheckedElement: PropTypes.bool,
   tooltipText: PropTypes.string,
};

export default Filter;
