import React from 'react';
import '../style.scss';
import PropTypes from 'prop-types'
import ResponsiveModal from 'common/components/modules/responsive-modal';
import PageHeader from 'common/components/modules/page-header';
import FullModal from 'common/components/modules/modal';
import {
   SitesAdministratorAgreement,
   AdminPrivacyPolicy,
   EndUserTermsOfService,
   PayoutsAndFees,
   TermsOfUse,
   SitesPrivacyPolicy,
   RecordKeeping,
   Dmca,
}  from './content';
import {
   SITES_ADMINISTRATOR_AGREEMENT,
   PRIVACY_POLICY,
   END_USER_TERMS_OF_SERVICE,
   PAYOUTS_AND_FEES,
} from '../admin/data';

import {
   TERMS_OF_USE,
   RECORD_KEEPING,
   DMCA,
} from '../sites/data';
import cx from 'classnames';

const Modal = ({ title, onClose, type, modalMode, modalHeight, modalWidth, isCloseAction, legalPages, whiteLabel }) => {
   let content;
   switch (title) {
      case SITES_ADMINISTRATOR_AGREEMENT: {
         content = <SitesAdministratorAgreement />;
         break;
      }
      case PRIVACY_POLICY: {
         content = type === 'admin' ? <AdminPrivacyPolicy /> : <SitesPrivacyPolicy legalPages={ legalPages } />;
         break;
      }
      case END_USER_TERMS_OF_SERVICE: {
         content = <EndUserTermsOfService />;
         break;
      }
      case PAYOUTS_AND_FEES: {
         content = <PayoutsAndFees />
         break;
      }
      case TERMS_OF_USE: {
         content = <TermsOfUse type={ type } legalPages={ legalPages } />
         break;
      }
      case RECORD_KEEPING: {
         content = <RecordKeeping type={ type } legalPages={ legalPages } />;
         break;
      }
      case DMCA: {
         content = <Dmca type={ type } legalPages={ legalPages } />;
         break;
      }
      default: break;
   }
   const siteAdminAgreementWrapperAdditionalStyles = {}
   if(title === SITES_ADMINISTRATOR_AGREEMENT) {
      siteAdminAgreementWrapperAdditionalStyles.padding = '0';
      siteAdminAgreementWrapperAdditionalStyles.marginTop = '60px';
      siteAdminAgreementWrapperAdditionalStyles.height = '100%';
   }

   if(title === RECORD_KEEPING) {
      title = '18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement';
   }
   return (
      modalMode ? <ResponsiveModal
         headerTitle={ title }
         width={ modalWidth }
         height={ modalHeight }
         children={ content }
         headerLeftIconAction={ onClose }
         withoutFooter={ true }
      /> : (
         <FullModal
            onClose={ onClose }
            contentPosition='full-screen'
            isCloseAction={ isCloseAction }
         >
            {/* <div className='second-modal-container'> */}
            {
               <PageHeader
                  title={ title }
                  fontIconSize='doubleLarge'
                  fontIconColor='major'
                  darkIconcolor='major-dark'
                  leftIconName='close'
                  leftIconAction={ onClose }
                  zIndex='20'
               />
            }
            <div
               className={ cx({
                  'second-modal-content second-modal-content-scroll ': true,
                  // 'ql-editor ql-text-major': whiteLabel,
               }) }
               style={ siteAdminAgreementWrapperAdditionalStyles }
            >
               {content}
            </div>
            {/* </div> */}
         </FullModal>)
   )
}

Modal.defaultProps = {
   modalMode: false,
   modalHeight: '697px',
   modalWidth: '658px',
};

Modal.propTypes = {
   title: PropTypes.string,
   onClose: PropTypes.func,
   type: PropTypes.oneOf([
      'admin',
      'sites',
   ]),
   modalMode: PropTypes.bool,
   modalHeight: PropTypes.string,
   modalWidth: PropTypes.string,
   isCloseAction: PropTypes.bool,
   whiteLabel: PropTypes.bool,
   legalPages: PropTypes.array,
}

export default Modal;










