import axios from 'axios';
import Auth from 'sites/utils/Auth';
import store from 'sites/state/store';
import { push } from 'connected-react-router';
import Router from 'sites/routes/router';
import { isLocalhost } from 'sites/utils/Helpers';
import { getProtocol } from 'common/utils/utils';
import logout from 'sites/utils/logout';

let sitesUrl;
const protocol = getProtocol();

if(isLocalhost()) {
   sitesUrl = `${ protocol }${ process.env.REACT_APP_SITES_API_ENDPOINT_FOR_DEVELOPMENT }/api`;
} else {
   const subdomain = document.querySelector('meta[name=subdomain]').content;
   sitesUrl = `${ protocol }${ subdomain }.${ process.env.REACT_APP_AMS_DOMAIN }/api`;
}
const amsUrl = `${ protocol }/${ process.env.REACT_APP_AMS_DOMAIN }`;

let controller = null;
// instance for auth requests
const client = axios.create();
client.interceptors.request.use((config) => {
   const result = Object.assign({}, config);
   result.headers.Authorization = `Bearer ${ Auth.getToken() }`;
   return config;
}, (error) => {
   return Promise.reject(error);
});

client.interceptors.response.use(
   response => {
      if(response.status === 200){
         window.isBlocked = false;
         window.isVpnBlocked = false;
      }
      if(response.request.responseURL.includes('/api/auth/init') && response.status === 200){
         window.blockedAdditionalData = null
      }
      return response;
   },
   error => {
      if(error.response) {
         switch (error.response.status) {
            case 401: {
               store.dispatch(logout(store.dispatch, false, null, null, true, 'SIGN_IN'))
               break
            }
            case 403: {
               const { response } = error
               if(response && response.data) {
                  ForbiddenError(response.data)
               }
               break;
            }
            default:{}
         }
      }
      throw error;
   }
);

function ForbiddenError(data) {
   const { error } = data
   switch (error) {
      case 'profile_blocked':{
         window.memberBanned = true;
         window.isBlocked = true
         const { data: { additional_info } } = data
         window.blockedAdditionalData = { additional_info: additional_info, error }
         localStorage.setItem('p_block', 'true')
         store.dispatch(logout(store.dispatch, false, null, null, true, 'ERROR_PAGE'))
         break
      }
      case 'ip_blocked':{

         window.isBlocked = true;
         const { data: { ip, additional_info } } = data

         let providerQuery = `?ip=${ ip }`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info, error }
         localStorage.setItem('ip_block', 'true')
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      case 'vpn_blocked':{
         window.isBlocked = true;
         window.isVpnBlocked = true;
         const { data: { ip, additional_info  } } = data

         let providerQuery = `?ip=${ ip }&vpn_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info, error }
         localStorage.setItem('v_block', 'true')
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      case 'country_blocked':{
         window.isBlocked = true;
         window.isCountryBlocked = true;
         const { data: { ip, additional_info  } } = data

         let providerQuery = `?ip=${ ip }&country_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info, error }
         localStorage.setItem('c_block', 'true')
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      case 'region_blocked':{
         window.isBlocked = true;
         window.isRegionBlocked = true;
         const { data: { ip, additional_info  } } = data

         let providerQuery = `?ip=${ ip }&region_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info, error }
         localStorage.setItem('r_block', 'true')
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      default:{}
   }
}

function cancelApiConfig(){
   let signal = {};
   if(controller){
      controller.abort();
   }
   controller = new AbortController();
   if(controller){
      signal = { signal: controller.signal };
   }
   return signal;
}

export function initSiteDetails(url) {
   let query = ''
   if(url) {
      query = `?referrer_url=${ url }`
   }
   return client.get(`${ sitesUrl }/auth/init${ query }`);
}

export function initStoriesDetails() {
   return client.get(`${ sitesUrl }/stories?count=20`);
}

export function getStoriesCount() {
   return client.get(`${ sitesUrl }/stories/stories-type-count`);
}

export function nextStoriesData(param) {
   const config = cancelApiConfig();
   return client.get(`${ sitesUrl }/stories?${ param.join('&') }`, config);
}

export function liekStory(id) {
   return client.get(`${ sitesUrl }/stories/${ id }/toggle-like`);
}

export function addView(id) {
   return client.post(`${ sitesUrl }/stories/${ id }/view`);
}

export function getStoryById(id) {
   return client.get(`${ sitesUrl }/stories/${ id }`);
}

export function refreshToken(data) {
   return client.post(`${ sitesUrl }/auth/refresh-token`, data);
}

export function getStoryComments(id) {
   return client.get(`${ sitesUrl }/stories/${ id }/comments?count=10`);
}
export function getStoryCommentsByFilter(id, param) {
   return client.get(`${ sitesUrl }/stories/${ id }/comments?${ param.join('&') }`);
}

export function storyCommentLike(id, comment_id) {
   return client.post(`${ sitesUrl }/stories/${ id }/comments/toggle-like`, { comment_id });
}

export function storyCommentDelete(id, comment_id) {
   return client.delete(`${ sitesUrl }/stories/${ id }/comments/${ comment_id }`);
}
export function sendStoryComment(data) {
   return client.post(`${ sitesUrl }/stories/comments`, data);
}
export function joinMailingList(data) {
   return client.post(`${ sitesUrl }/join-to-mailing-list`, data);
}

export function bypassCheck(data) {
   return client.post(`${ sitesUrl }/auth/bypass-check`, data);
}


// photosets
export function initPhotosetsDetails(params = [], canceled) {
   let config
   if(canceled){
      config = cancelApiConfig();
   }
   return client.get(`${ sitesUrl }/photosets?${ params.join('&') }`, config);
}
export function getPhotosetById(id) {
   return client.get(`${ sitesUrl }/photosets/${ id }`);
}
export function photosetToggleLike(id) {
   return client.post(`${ sitesUrl }/photosets/${ id }/toggle-like`);
}
export function addPhotosetView(id) {
   return client.post(`${ sitesUrl }/photosets/${ id }/view`);
}
export function photosetAvailablePrices(id) {
   return client.get(`${ sitesUrl }/photosets/${ id }/available-prices`);
}
export function photosetPreReleaseForFree(id) {
   return client.get(`${ sitesUrl }/photosets/${ id }/prerelease-for-free`);
}
export function photosetToggleWatched(id, data) {
   return client.post(`${ sitesUrl }/photosets/${ id }/make-watched`, data);
}

export function getRelatedContents(id, type, params = [], canceled = true) {
   let config
   if(canceled){
      config = cancelApiConfig();
   }
   return client.get(`${ sitesUrl }/${ type }/${ id }/related?${ params.join('&') }`, config);
}

// videos
export function initVideosDetails(params = [], canceled) {
   let config = {}
   if(canceled){
      config = cancelApiConfig();
   }
   return client.get(`${ sitesUrl }/videos?${ params.join('&') }`, config);
}
export function getVideoById(id, apiQuery) {
   return client.get(`${ sitesUrl }/videos/${ id }${ apiQuery || '' }`);
}

export function videoToggleLike(id, apiQuery = '') {
   return client.post(`${ sitesUrl }/videos/${ id }/toggle-like${ apiQuery }`);
}
export function videoAvailablePrices(id, apiQuery = '') {
   return client.get(`${ sitesUrl }/videos/${ id }/available-prices${ apiQuery }`);
}
export function mediaAvailablePrices(id, type, apiQuery = '') {
   return client.get(`${ sitesUrl }/${ type }/${ id }/available-prices?${ apiQuery }`);
}
export function videoPreReleaseForFree(id) {
   return client.get(`${ sitesUrl }/videos/${ id }/prerelease-for-free`);
}

export function fetchMediaFilterResources(delegator, type = 'video') {

   let resources = 'cast_members,tags,'

   switch (type) {
      case 'video':
         resources += 'video_collections'
         break;
      case 'photoset':
         resources += 'photoset_collections'
         break;
      default:
         break;
   }


   let url = `${ sitesUrl }/filtration-resources?delegator=${ delegator }&resources=${ resources }`;

   return client.get(url);
}
export function addVideoView(id) {
   return client.post(`${ sitesUrl }/videos/${ id }/view`);
}
export function videoToggleWatched(id, apiQuery = '', data) {
   return client.post(`${ sitesUrl }/videos/${ id }/make-watched${ apiQuery }`, data)
}

// media comments api
export function getMediaComments(id, type) {
   return client.get(`${ sitesUrl }/${ type }/${ id }/comments?count=20`);
}
export function getMediaCommentsByFilter(id, param = [], type) {
   return client.get(`${ sitesUrl }/${ type }/${ id }/comments?${ param.join('&') }`);
}

export function mediaCommentLike(id, comment_id, type) {
   return client.post(`${ sitesUrl }/${ type }/${ id }/comments/toggle-like`, { comment_id });
}

export function mediaCommentDelete(id, comment_id, type) {
   return client.delete(`${ sitesUrl }/${ type }/${ id }/comments/${ comment_id }`);
}
export function sendMediaComment(data, type) {
   return client.post(`${ sitesUrl }/${ type }/comments`, data);
}

export function initHomePageData(siteHeader) {
   return client.get(`${ sitesUrl }/homepage?test_site_header=${ siteHeader }`);
}
export function initComingSoon() {
   return client.get(`${ sitesUrl }/coming-soon`);
}

// store

export function initStores(params = [], canceled) {
   let config = {};
   if(canceled){
      config = cancelApiConfig();
   }
   return client.get(`${ sitesUrl }/products?${ params.join('&') }`, config);
}
export function initStoreById(id) {
   return client.get(`${ sitesUrl }/products/${ id }`);
}

export function initCategories() {
   return client.get(`${ sitesUrl }/product-collections`);
}
export function appInfoBlockingState() {
   return client.get(`${ sitesUrl }/info`);
}
export function unsubscribeMail(data) {
   return client.post(`${ sitesUrl }/unsubscribe`, data);
}
export function fetchCustomPage(id) {
   return client.get(`${ sitesUrl }/custom-pages/${ id }`);
}

export function requestForPurchase(data){
   return client.post(`${ sitesUrl }/payments/store-payment-validate`, data)
}

export function sendNewOrderRequest(data){
   return client.post(`${ sitesUrl }/products/orders/request`, data)
}

export function subscribedForNotify(data){
   return client.post(`${ sitesUrl }/products/notify-me-product-in-stock`, data)
}

// start live stream api
export function initLiveStream() {
   return client.get(`${ sitesUrl }/live-stream/init`);
}
export function liveStreamDetails() {
   return client.get(`${ sitesUrl }/live-stream/details`);
}
export function liveStreamAvailablePrices(id) {
   return client.get(`${ sitesUrl }/live-stream/${ id }/available-prices`);
}
// end live stream api

// promotions
export function validatePromoCode(data) {
   return client.post(`${ sitesUrl }/membership-pricings/validate-promo-code`, data)
}

// affiliates details

export function getAffiliatesDetails(appId) {
   return client.get(`${ sitesUrl }/affiliate/details/${ appId }`);
}

export function getObjectUrl(data) {
   return client.get(`${ amsUrl }/api/v2/get-object-url-2?paths[]=${ data.paths }&pathName=${ data.pathName }`);
}
// export function uploadPhotos(data) {
//    return client.get(`${ amsUrl }/api/v2/get-object-url-2?paths[]=${ data.paths }&pathName=${ data.pathName }`);
// }

export function uploadImageApi(file, path = 'photoset-image-upload-v2', canceled = false) {
   let signal = {};
   if(canceled){
      const controller = new AbortController();
      controller.abort();
      signal = { signal: controller.signal };
   }
   return client.post(`${ amsUrl }/image-uploader/${ path }`, file, signal);
}

export function verifyAgeById(data) {
   return client.post(`${ sitesUrl }/adult-verification/verify`, data)
}

export function setVerifiedByToken(token) {
   return client.post(`${ sitesUrl }/adult-verification/verify-by-token`, { token })
}

//favorite
export function addOrRemoveFavoriteItem(data) {
   return client.post(`${ sitesUrl }/my-favorites`, data)
}

export function getFavouritesData(params = [], canceled) {
   let config = {}
   if(canceled){
      config = cancelApiConfig();
   }
   return client.get(`${ sitesUrl }/my-favorites?${ params.join('&') }`, config)
}
