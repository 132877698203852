
import {
   initLiveStream,
   // liveStreamDetails,
   joinMailingList,
   initVideosDetails,
   videoToggleLike,
   videoToggleWatched,
}  from 'sites/api/MixApi';
import {
   getLiveStreamMessages,
   getLiveStreamMessageLikedMembers,
   memberToggleNotifications,
   likeStreamLike,
   likeStreamMemberActivity,
}  from 'sites/api/AuthApi';

import {
   fetchInitRequest,
   fetchInitCompleted,
   fetchLiveStreamMessagesRequest,
   fetchLiveStreamMessagesRequestCompleted,
   fetchNewMessagesRequest,
   fetchNewMessagesCompleted,
   fetchLikedMemberByMessageRequest,
   fetchLikedMemberByMessageCompleted,
   setOpenActiveUpsellModal,
   setOfflineStateAction,
   fetchRecordsInitRequest,
   fetchRecordsCompleted,
   fetchNextRecordsRequest,
   fetchNextRecordsCompleted,
   liveStreamLikeRequest,
   liveStreamLikeCompleted,
   likeRecordStream,
   toggleWatchedCompleted,
   updateDataAfterPurchaseCompleted,
} from './actions'
import ChatHepler from 'common/utils/chat';
import { setChatPanelAction, setActiveTab } from 'sites/state/modules/chat/actions';
// import toster from 'common/utils/toast';
import {
   fetchResourcesOperation,
}  from 'sites/state/modules/media-resources/operations';
import Router from 'sites/routes/router';
export function initLiveStreamOperation(inited = true, socketupdate = false, callBack) {
   return async (dispatch, getState) => {
      if(!!inited) {
         dispatch(fetchInitRequest(true));
      }
      try {
         const { data }  = await initLiveStream();
         if(!inited){
            if(socketupdate) {
               const { livestream, chat, router: { location: { pathname } } } = getState();
               const { initData: { stream_access_type, is_stream_offline, is_live_stream_session_active }, isOpenActiveUpsellModal } = livestream;
               const { chatPanel } = chat;
               const socket = window.siteLiveStreamSocket;

               if(is_stream_offline !== data.is_stream_offline && !!data.is_stream_offline) {
                  // toster.error('offline')
                  if(socket){
                     socket.emit('watching', { isWatching: false });
                  }
                  if(!!chatPanel?.isOpen  && !!Router.route('LIVE_STREAM_STUDIO').match(pathname)){
                     dispatch(setChatPanelAction({ isOpen: false, closeChatMessages: false, animationClass: {} }))
                  }
               } else {

                  if(is_live_stream_session_active !== data.is_live_stream_session_active && !!data.is_live_stream_session_active) {
                     if(!!Router.route('LIVE_STREAM_STUDIO').match(pathname) && socket && 'unlocked' === data.stream_access_type){
                        socket.emit('watching', { isWatching: true });
                     }
                  }
                  if(stream_access_type !== data.stream_access_type) {
                     if(!!Router.route('LIVE_STREAM_STUDIO').match(pathname) && socket){
                        if('unlocked' === data.stream_access_type){
                           socket.emit('watching', { isWatching: true });
                        } else {
                           socket.emit('watching', { isWatching: false });
                        }
                     }
                     if('locked' === data.stream_access_type  || 'not_available' === data.stream_access_type) {
                        dispatch(setOpenActiveUpsellModal(true));
                     } else if(isOpenActiveUpsellModal){
                        dispatch(setOpenActiveUpsellModal(false));
                     }

                  }
                  if(data.is_live_stream_session_active  && !data.is_stream_offline && !chatPanel?.isOpen && !!Router.route('LIVE_STREAM_STUDIO').match(pathname)){
                     dispatch(autoOpenChatPannelOperation());
                  } else if(chatPanel?.isOpen && !(data.is_live_stream_session_active  && !data.is_stream_offline)){
                     dispatch(setChatPanelAction({ isOpen: false, closeChatMessages: false, animationClass: {} }))
                  }
               }
            }
            dispatch(fetchInitCompleted({ ...data, is_show_banner: true }));
            if(callBack) {
               callBack(data)
            }

         } else {
            dispatch(fetchInitCompleted({ ...data, is_show_banner: true }));
            if(callBack) {
               callBack(data)
            }
         }
      } catch (error) {
         console.log(error)
         dispatch(fetchInitRequest(false));
      }
   }
}


export const autoOpenChatPannelOperation = _ => {
   return async (dispatch, getState) => {
      dispatch(fetchLiveStreamMessagesRequest(true));
      try {
         dispatch(fetchLiveStreamMessagesOperation());
         const {
            chat: { chatPanel },
            common: { screenWidth },
         } = getState()
         let bool = !chatPanel.isOpen
         let data = {
            isMobile: screenWidth < 1025,
            isOpen: bool,
            animationClass: {
               boxShadow: '-8px 0px 12px rgba(0, 0, 0, 0.25)',
               transform: 'translateX(0)',
               WebkitTransform: 'translateX(0)',
            },
         }
         if(bool){
            data = {
               ...data,
               closeChatMessages: bool,
            }
         }
         dispatch(setChatPanelAction({ ...data, closeChatMessages: bool }))
         dispatch(setActiveTab({
            type: 'livestream',
            id: 'live_stream',
            conversation: {
               conversation_name: 'Livestream chat',
               avatars: null,
            },
         }))
      } catch (error) {
      }
   };
};
export const fetchLiveStreamMessagesOperation = _ => {
   return async (dispatch) => {
      dispatch(fetchLiveStreamMessagesRequest(true));
      try {
         const { data } =  await getLiveStreamMessages();
         dispatch(fetchLiveStreamMessagesRequestCompleted(data));
         ChatHepler.syncDialogueScroll(null, 'live_stream')
      } catch (error) {
         dispatch(fetchLiveStreamMessagesRequest(false))
      }
   };
};

export const fetchNewMessagesOperation = (query = []) => {
   return async (dispatch) => {
      dispatch(fetchNewMessagesRequest(true));
      try {
         const { data } =  await getLiveStreamMessages(query);
         dispatch(fetchNewMessagesCompleted(data));
      } catch (error) {
         if(error.response) {
            dispatch(fetchNewMessagesRequest(false));
         }
      }
   };
};
export const fetchLikedMemberByMessageOperation = (query, id) => {
   return async (dispatch) => {
      dispatch(fetchLikedMemberByMessageRequest(!!query));
      try {
         const { data } =  await getLiveStreamMessageLikedMembers(query, id);
         dispatch(fetchLikedMemberByMessageCompleted({ data, isPagination: !!query }));
      } catch (error) {
         if(error.response) {
            dispatch(fetchLikedMemberByMessageRequest(false));
         }
      }
   };
};
export const onClickNotifyAndAddMailgListOperation = (isAuth, email) => {
   return async (dispatch) => {
      try {
         dispatch(setOfflineStateAction({
            buttonLoading: true,
            isSubscrib: false,
            emailError: null,
         }));

         if(isAuth) {
            await memberToggleNotifications({ active: true })
         } else {
            await joinMailingList({ email, subscription_type: 'livestream' })
         }
         dispatch(setOfflineStateAction({
            buttonLoading: false,
            isSubscrib: true,
         }));
      } catch (error) {
         if(error.response) {
            dispatch(setOfflineStateAction({
               buttonLoading: false,
               isSubscrib: false,
               emailError: error.response?.data?.email || null,
            }));
         }
      }
   };
};
export const fetchLiveStreamRecordStreamsOperation = (params = [], isByFilter, initCall = false) => {
   return async (dispatch) => {
      try {
         dispatch(fetchRecordsInitRequest(true, !initCall));
         if(initCall) {
            await dispatch(fetchResourcesOperation('livestream', 'video'));
         }
         params.push('video_type=livestream');
         const { data } = await initVideosDetails(params);
         dispatch(fetchRecordsCompleted(data, isByFilter));

      } catch (error) {
         dispatch(fetchRecordsInitRequest(false, !initCall));
      }
   };
};
export const toggleLikeRecordStreamOperation = (id) => {
   return async dispatch => {
      // dispatch(actions.toggleLikeRequest());
      try {
         let apiQuery = '?video_type=livestream';
         await videoToggleLike(id, apiQuery);
         dispatch(likeRecordStream(id))
      } catch (error) {
         // dispatch(actions.toggleLikeFailed(error));
      }
   }
};
export const fetchNextRecordOperation = (params = []) => {
   return async (dispatch) => {
      try {

         dispatch(fetchNextRecordsRequest(true));
         params.push('video_type=livestream');
         const { data } = await initVideosDetails(params);
         dispatch(fetchNextRecordsCompleted(data));

      } catch (error) {
         dispatch(fetchNextRecordsCompleted(false));
      }
   };
};
export const liveStreamLikeOperation = callBack => {
   return async (dispatch) => {
      dispatch(liveStreamLikeRequest(true));
      try {
         await likeStreamLike();
         dispatch(liveStreamLikeCompleted());
         if(callBack){
            callBack()
         }
      } catch (error) {
         dispatch(liveStreamLikeRequest(false))
         if(callBack){
            callBack()
         }
      }
   };
};
export const toggleWatchedRecordStreamOperation = (id, data) => {
   return async dispatch => {
      try {
         let apiQuery = '?video_type=livestream';
         await videoToggleWatched(id, apiQuery, data);
         dispatch(toggleWatchedCompleted(id))
      } catch (error) {
      }
   }
};

export function updateRecordsDataAfterPurchaseOperation(data) {
   return async dispatch => {
      try {
         let apiQuery = '?video_type=livestream';
         await videoToggleWatched(data.id, apiQuery, { watched: true });
         dispatch(updateDataAfterPurchaseCompleted(data))
      } catch (error) {
      }
   }
}
export const likeStreamMemberActivityOperation = _ => {
   return async (dispatch) => {
      try {
         await likeStreamMemberActivity();

      } catch (error) {
      }
   };
};
