import {
   fetchMediaFilterResources,
}  from 'sites/api/MixApi.js';
import { downloadMedia } from 'sites/api/AuthApi';
import { downloadMediaFromUrl } from 'common/utils/utils'
import toast from 'common/utils/toast';

import {
   fetchResourcesRequest,
   fetchResourcesCompleted,
   fetchResourcesFailed,

   setDownloadButtonLoading,
   setWrongDownloadButtonModal,
} from './actions';

export function fetchResourcesOperation(delegator, type = 'video') {
   return async (dispatch) => {
      dispatch(fetchResourcesRequest());
      try {
         const { data } = await fetchMediaFilterResources(delegator, type);
         dispatch(fetchResourcesCompleted(data));
      } catch (error) {
         dispatch(fetchResourcesFailed());
      }
   };
}
export function downloadMediaOperation(id, type, payload) {
   return async dispatch => {
      dispatch(setDownloadButtonLoading(true));
      try {
         const { userAgent } = window.navigator;
         const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
         const isIosSafari = /iP(ad|od|hone)/i.test(userAgent) && /WebKit/i.test(userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent));
         if(type === 'videos' && isIos && !isIosSafari) {
            dispatch(setWrongDownloadButtonModal({
               isOpen: true,
               body: 'Video downloads are currently only available in Safari.',
            }))
            dispatch(setDownloadButtonLoading(false));
         } else {
            const { data } = await downloadMedia(id, type, payload)
            downloadMediaFromUrl(data.link)
            dispatch(setDownloadButtonLoading(false));
         }

      } catch (error) {
         if(error.response && (error.response.data && !!error.response.data.body)){
            const { data } = error.response
            let isLimitedModal = false;
            if(429 === error.response.status) isLimitedModal = true;
            dispatch(setWrongDownloadButtonModal({
               isOpen: true,
               body: data.body,
               header: data.header,
               is_limited: isLimitedModal,
            }))
         } else {
            toast.error('Something went wrong');
         }
         dispatch(setDownloadButtonLoading(false));
      }
   }
}
